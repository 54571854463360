/* Fonts
----------------------------------------------------------------------------------------------------*/
@import url(https://fonts.googleapis.com/css?family=Rajdhani:600,700,400|Roboto:400,700|Martel:400);


body{font-family: 'Roboto', sans-serif; font-size: 15px; color: #373737; padding-top: 80px;}
*:focus{outline: none!important}
.turbolinks-progress-bar {
  height: 5px;
  background-color: #fb8512;
}
a{color: #4c7bb1;-webkit-transition: all 0.3s;-moz-transition: all 0.3s;-o-transition: all 0.3s;-ms-transition: all 0.3s;transition: all 0.3s;}
a:hover, a:focus{text-decoration: none;}
img{max-width: 100%}
.pt30{padding-top: 30px;}

h1, h2, h3{font-family: 'Rajdhani' ,sans-serif; font-weight: 600;}
h3.module-title{font-weight: 600; border-bottom: 1px solid #ff9800; margin: 0 0 15px; padding-bottom: 5px;}
.module h3.module-title{font-weight: 300!important; display: block!important; font-size: 20px; color: #000;}
strong{font-family: 'Rajdhani', sans-serif; font-size: 18px; font-weight: 600;}
label{font-weight: 600;}

.mt70{margin-top: 70px;}
.mt30{margin-top: 30px;}

.grid-item { width: 250px; margin: 10;}

/*BTN*/
.btn{border-radius: 0px; border: none; padding: 4px 20px; font-family: 'Roboto', sans-serif;}
.btn-danger{background-color: #FF9700;}
.btn-danger:hover, .btn-danger:focus{background-color: #D46400;}
.btn-warning{background-color: #ff9800; color: #fff!important;}
.btn-warning:hover, .btn-warning:focus{background-color: #e38f14;}
.btn-primary{background-color: #7E8AA2; color: #fff!important;}
.btn-primary:hover, .btn-primary:focus{background-color: #596A8C;}
#load-more{padding: 10px 30px; font-family: 'Rajdhani', sans-serif; font-weight: 600; font-size: 18px; color: #fff!important;}

/*Pagination*/
.pagination>.active>a, .pagination>.active>a:focus, .pagination>.active>a:hover, .pagination>.active>span, .pagination>.active>span:focus, .pagination>.active>span:hover{background-color: #7D869F; border-color: #7D869F; }
.pagination>li>a, .pagination>li>span{color: #7D869F; }
.pagination>li>a:focus, .pagination>li>a:hover, .pagination>li>span:focus, .pagination>li>span:hover{color: #7D869F;}

/*Form*/
.form-control{border: 1px solid #CACACA; border-radius: 0px; margin-bottom: 10px; box-shadow: none}
.form-control:focus{border-color: #FFCB7F; box-shadow: none}
button.btn{padding: 4px 20px; font-family: 'Rajdhani', sans-serif; font-weight: 600; font-size: 18px;}
form li{vertical-align: top;}

/* Social-icons */
.social-icons{margin-left: 0;}
.social-icons a{background-color: #fff; border: 1px solid #CACACA; border-radius: 100%; display: inline-block; width: 33px; height: 33px; text-align: center; line-height: 2.5; font-size: 15px!important;}
.social-icons a:hover{ transform: scale(1.2) rotate(3600deg);}
.social-icons a .fa{font-size: 18px;}
.social-icons a .fa-linkedin{color: #0976B4; position: relative; top: -2px;}
.social-icons a .fa-facebook{color: #3B5998;}
.social-icons a .fa-twitter{color: #55ACEE;}
.social-icons a .fa-pinterest-p{color: #BD2000;}
.social-icons a .fa-envelope-o{color: #7D8AA1; position: relative; top: -1px;}

/*Modules*/
.module{margin-top: 0; padding: 15px 20px 20px; background-color: #F6F9FF; line-height: 1.8; overflow: hidden; margin-bottom: 20px;}
.module li{margin-bottom: 10px; overflow: hidden;}
.module img{max-height: 100px;}
.module h4{margin: 5px 0; font-size: 15px; font-weight: bold;}
.module a{color: #0070bd;}
.module p{line-height: 1.3; margin: 0;}
.module .more-things{float: right; color: #fff;}
.module a.book-link, .module a.author-link{float: left;  margin-right: 10px;}

/*popular-topics module*/
.popular-topics{}
.popular-topics li{display: inline-block; width: 49%; margin-bottom: 0; line-height: 1;}
.popular-topics li a{color: #0070bd; text-decoration: underline;}

/*authors-biographies module*/
.module.authors-biographies img{max-height: 63px}
.module.authors-biographies .info{font-size: 13px;}
.module.authors-biographies a{color: #373737;}
.module.authors-biographies .occupation-list li a{color: #0070bd;}

/*popular-articles*/
.popular-articles{}
.popular-articles img{float: left; max-width: 80px; margin-right: 10px;}
.popular-articles h4{margin: 0;}
.popular-articles li{margin-bottom: 20px;}

/*books-of-author*/
.books-of-author, .quotes-of-author{margin: 20px 0;}


/* Carousel */
.carousel{}
.carousel .item img{ width: 100%;}
.carousel .carousel-control{ opacity: 1; background-image: none;width: 50px;height: 50px;top: 50%;margin-top: -30px;line-height: 55px;text-shadow: none; color: #fff; }
.carousel .carousel-control:hover{border-color: #FF9700; color: #FF9700;}
.carousel .carousel-control:hover:after{border-color: transparent #FF9700 transparent transparent; }
.carousel .carousel-control.right:hover:after{border-color: #FF9700 transparent transparent transparent; }
.carousel .carousel-control .fa{font-size: 50px; margin-left: -3px;}
.carousel .carousel-control.right .fa{margin-right: -5px;}
.carousel .carousel-indicators{bottom: 0;}
.carousel .carousel-indicators li{border: none; margin: 0 15px; border-radius: 0px; background-color: #fff; width: 13px; height: 13px;}
.carousel .carousel-indicators li:hover{background-color: #7E8AA2;}
.carousel .carousel-indicators li.active{border: none; margin: 0 15px; background-color: #FF9700; width: 13px; height: 13px;}

/* Header */
#header{position: fixed; width: 100%; background-color: #fff; z-index: 100; top: 0;}
#header .logo{ position: relative; z-index: 99; display: block;}
#header .logo img{max-height: 40px;}
#header #top{padding: 30px 0 15px; overflow: hidden;}
#header #logo{ display: inline-block; float: left;}
#header #logo .white{display: none;}
#header #menu-bar{position: relative; height: inherit; margin: 9px 0 0;}

/*social-media*/
.social-media{float: right; }
.social-media a{opacity: 0.9;color: #fff; background-color: #3B5998; display: inline-block; padding: 5px; width: 36px; text-align: center; height: 36px; font-size: 20px; border-radius: 100%;}
.social-media li:first-of-type a{background-color: #c9232d;}
.social-media li:nth-of-type(2) a{background-color: #0976b4;}
.social-media li:nth-of-type(3) a{background-color: #3b5998;}
.social-media li:last-of-type a{background-color: #55acee;}
.social-media a:hover{opacity: 1;}
.fixed-social {position: fixed; left: 0; top: 50%; margin-top: -100px; z-index: 200;}
.fixed-social .social-media li{display: block;}
.fixed-social .social-media li a{border-radius: 0px; width: 50px; height: 50px; line-height: 2;}

/* NavBar */
#header {background-color: #263248; padding: 10px 0;}
#header .navbar-inverse{border-radius: 0; background-color: #263248; min-height: inherit; margin-bottom: 0;}
#header .navbar-nav{text-align: center; min-height: inherit; text-align: center; float: none;     margin: 0 -50px;}
#header .navbar-nav>li{ float: none; display: inline-block; vertical-align: top;}
#header .navbar{min-height: inherit; margin: 2px 0 0;}
#header .navbar .navbar-collapse{padding: 0;}
#header .navbar a{position: relative; font-family: 'Rajdhani', sans-serif; color: #fff; font-size: 15px; font-weight: 500; padding: 0;margin: 0 15px; background: transparent;}
#header .navbar a.btn, #header .navbar a.btn:active{padding: 2px 15px; background-color: #FE9700;}
#header .navbar a.btn:hover{background-color: #D46400;}
#header .navbar a .fa{font-size: 22px;}
#header .navbar .active a:not(.btn){background-color: transparent; }
#header .navbar .active a:after, #header .navbar-nav>li a:hover:after{content: ''; position: absolute; bottom: -7px; width: 60%; background-color: #FF9700; height: 4px; border-radius: 5px; left: 20%;}
#header .navbar-nav>li a.btn:hover:after{display: none;}


#header .right-menu {float: right; margin: 0;}
#header .right-menu li{vertical-align: top;}
#header .right-menu a{color: #fff;}
#header .right-menu a.open-search{font-size: 23px; margin-top: 2px; display: inline-block;}

/*Aside*/
aside section{margin-bottom: 30px;}

/*quote-day*/
section#quote-day .wrapper{  padding: 30px 5% 20px 10%; background: url(../img/bg-quote.png) no-repeat 11% center}
section#quote-day h2{color: #ff9800; font-weight: 600;}
section#quote-day h4{padding: 20px 0; font-size: 30px; font-family: 'Martel', sans-serif;}
section#quote-day footer{padding: 10px 0;}
section#quote-day .social-icons{float: left;}
section#quote-day .author a{float: right; font-size: 16px; color: #4a90e2; font-weight: bold;}

/*advertisement*/
section.advertisement{padding: 100px 0; background-color: #e7e7e7; color: #7e8aa2; text-align: center; font-size: 18px; margin-bottom: 30px;}
section.advertisement a{color: #7e8aa2!important;}
section.advertisement-middle{padding: 30px 0;}

/*popular-things*/
#popular-things{}
#popular-things ul{margin-bottom: 0;}
#popular-things section p{color: #696969; margin-bottom: 20px;}
#popular-things section li a{color: #0070bd ; margin-bottom: 5px; display: inline-block;  overflow: hidden; text-overflow: ellipsis; white-space: nowrap; width: 100%;}
#popular-things .more-btn{display: none;}
#popular-things .col-sm-4:last-child .more-btn{display: inline-block;}

/*images-quotes*/
#images-quotes{}
#images-quotes h1{color: #ff9800; margin-bottom: 30px; margin-top: 0; font-size: 30px;}
#images-quotes .one-image-quote{padding: 5px; border-radius: 4px; box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.13); border: solid 1px #d0d0d0; max-width: 300px; margin: 0 auto 30px;}
#images-quotes .one-image-quote h4{margin: 0; padding: 0 0 5px; text-align: center; line-height: 1; font-size: 15px; font-weight: bold;}
#images-quotes .one-image-quote img{width: 100%;}
#images-quotes .one-image-quote .row{margin-bottom: 5px;}
#images-quotes .one-image-quote .small-images{    margin-right: -3px; margin-left: -3px;}
#images-quotes .one-image-quote .col-xs-6{padding-left: 3px; padding-right: 3px;}

/*not-found-page*/
#not-found-page{min-height: 100%; text-align: center; padding: 100px 0;}
#not-found-page h1{font-family: 'Roboto'; font-size: 150px; color: #FF9700;}
#not-found-page h1{}

/*latest-success*/
.latest-success img{width: 60px; margin-bottom: 28px;}

/*latest-authors*/
.latest-authors img{width: 60px;}

/*blue-aria*/
#blue-aria{background-color: #F6F9FF; overflow: hidden; padding: 10px 0; margin-top: 30px;}
#blue-aria h2{color: #ff9800;}
#blue-aria .module{padding: 20px 0; min-height: 510px; position: relative;}
#blue-aria img{float: left; margin-right: 10px; }
#blue-aria li{overflow: hidden;}
#blue-aria h4{margin: 5px 0; font-size: 15px; font-weight: bold;}
#blue-aria a{color: #0070bd;}
#blue-aria h4 a{color: #373737;}
#blue-aria p{line-height: 1.3; margin: 0; color: #696969; font-size: 14px; line-height: 1.3;}
#blue-aria .more-things{color: #fff; position: absolute;bottom: 0; right: 0;}


.latest-image-quotes img{margin-right: 0; width: 100%; max-height: inherit;}
.latest-image-quotes li{margin-bottom: 26px;}
.latest-books img{max-height: 75px}

/*Banner*/
.banner .wrapper{background-color: #FFF2DF;}

/*subscribe*/
#subscribe{}
#subscribe h2{font-size: 28px; color: #ff9800; line-height: 1; margin-top: 15px;}
#subscribe form{margin-top: 25px; padding-right: 30px; vertical-align: top}

/*Footer*/
#footer{background-color: #263249; color: #fff; padding: 10px 0; border-top: 4px solid #9FA7B5; position: relative;}
#footer #gotop{font-size: 30px; right: 20px; background-color: #FA8412; color: #fff; width: 50px;height: 50px; text-align: center;line-height: 1.5; border-radius: 100%;  bottom: 20px; position: fixed; z-index: 66; }
#footer a{color: #fff; font-size: 13px;} 
#footer h4{font-size: 15px; font-weight: bold;}
#footer #logo-footer{margin-top: 30px; display: block;}
#footer .social-icons{margin-top: 60px; text-align: center; }
#copyright{background-color: #2C2C2C; color: #fff; text-align: center; padding: 5px; font-size: 13px;}


/*quote-page*/
#quote-page{padding: 30px 0;}
#quote-page article{margin-bottom: 10px; overflow: hidden;}
#quote-page .letter{color: #FF9700; padding: 30px 0 0; font-weight: bold; font-size: 34px;border-bottom: 1px solid; margin-bottom: 20px;}
#quote-page .letter-content li{width: 24%; vertical-align: top; line-height: 1.8;}
#quote-page a .number{color: #9b9b9b}

/*the-content*/
#the-content{padding: 30px 0; min-height: 500px}
.wrapper .module{padding: 10px 20px 0 0; margin-left: -10px;}
.wrapper .module h3.module-title{font-size: 18px;}
.wrapper-blue{background-color: #F6F9FF;}
.wrapper-yellow{background-color: #fff2de;}

/*author-bibliographie*/
.author-bibliographie{margin-bottom: 50px;}
.author-bibliographie h1.name{font-size: 24px; margin: 0 0 10px; font-weight: 600; font-size: 30px;}
.author-bibliographie .info-author{margin: 0 0 20px; color: #696969;}
.author-bibliographie .more-info{margin-top: 20px;}
.author-bibliographie .more-info li{margin-bottom: 10px;}


/*quote-authors-page*/
.quote-authors-page{padding-top: 30px;}

/*all-quotes*/
#all-quotes{margin-top: 30px;}
#all-quotes header{overflow: hidden; border-bottom: 1px solid #FF9700; margin: 10px 0 20px; padding-bottom: 0;}
#all-quotes h1{font-weight: 600; display: inline; font-size: 24px;}
#all-quotes #the-topics, #all-quotes #the-authors{display: inline; margin-left: 30px;}
#all-quotes #orderby{float: right; padding: 0; margin: 0 5px 5px 0;}

#all-quotes .one-quote{border: 1px solid #D1D1D1; padding: 1px; margin-bottom: 30px;}
#all-quotes .one-quote .wrapper{padding: 15px 0 0; }
#all-quotes .one-quote .quote-img{width: 100%;}
#all-quotes .one-quote .social-icons{display: block; text-align: center;}
#all-quotes .one-quote .social-icons li{padding: 0 1px;}
#all-quotes .one-quote blockquote{position: relative; padding: 15px 10px 0; margin-bottom: 0; border: none; font-size: 14px; background-color: #fff;}
#all-quotes .one-quote blockquote a{color: #1b1b1b; font-size: 14px;}
#all-quotes .one-quote blockquote cite{text-align: right; display: block;}
#all-quotes .one-quote blockquote cite a{color: #0070bd; }
#all-quotes .one-quote hr{margin: 0 0 10px; border-color: #979797;}
#all-quotes .one-quote .topics-list{padding: 4px;border-top: 1px solid #ccc;margin: 0px -1px;}
#all-quotes .one-quote .topics-list li{ padding: 0; margin: 0; vertical-align: baseline;}
#all-quotes .one-quote .topics-list li a{font-size: 12px; padding: 2px 5px;border: 1px solid #ccc;border-radius: 5px;}

#all-quotes .with-image blockquote{width: 100%; }


/*Topics*/
#topics-action, #the-topics, #authors-action, #the-authors {display: inline;}
#the-topics li:nth-of-type(n+4), #the-authors li:nth-of-type(n+4){display: none;}
#the-topics.display-all li:nth-of-type(n+5), #the-authors.display-all li:nth-of-type(n+5){display: inline;}

#all-quotes .images-filter{margin-bottom: 15px;}
#all-quotes .images-filter #the-topics, #all-quotes .images-filter #the-authors{margin-left: 0;} 
#all-quotes .images-filter a{text-decoration: underline;}
#all-quotes .images-filter .filter-author{text-align: right;}


/* Caroufdsel */
.list_carousel ul {padding: 0; list-style: none; display: block; }
.list_carousel li {text-align: center; width: 160px!important; height: 200px; padding: 0; margin-right: 6px; display: block; float: left; }
.list_carousel.responsive {width: auto; margin-left: 0; }
.caroufredsel_wrapper{margin-bottom: 0!important;}
.clearfix {float: none; clear: both; }

.list_carousel {position: relative; }
.list_carousel .btn-control{position: absolute; opacity: 1; background-image: none; width: 50px; height: 50px; top: 50%; margin-top: -30px; line-height: 55px; left: -36px; color: #7E8AA2; text-align: center; color: #000;}
.list_carousel .btn-control.next{left: auto; right: -37px}
.list_carousel .btn-control .fa{font-size: 30px; margin-left: -3px;}

.list_carousel .btn-control:hover{border-color: #FF9700; color: #FF9700;}
.list_carousel .btn-control.next:after{border-width: 10px 10px 0 0; border-color: #7E8AA2 transparent transparent transparent; left: inherit; right: -2px;}
.list_carousel .btn-control:hover:after{border-color: transparent #FF9700 transparent transparent; }
.list_carousel .btn-control.next:hover:after{border-color: #FF9700 transparent transparent transparent; }
.list_carousel .btn-control .fa{font-size: 50px; margin-left: -3px;}
.list_carousel .btn-control.next .fa{margin-right: -5px;}



/*Books-by*/
.books-by{text-align: center; padding: 10px 0 30px;}
.books-by li{margin: 0 -8px;}
.books-by a{position: relative; font-family: 'Rajdhani', sans-serif; display: inline-block; color: #263248; background-color: #F6F9FF; border: 1px solid #E8E9EC; padding: 7px 0; text-align: center; width: 140px; font-weight: 600; text-transform: capitalize;}
.books-by .active a, .books-by a:hover{color: #ff9800; background-color: #FFF2DF; border-color: #FFC56F; z-index: 9;}

.alphabet{text-align: center; margin-bottom: 50px;}
.alphabet li{padding: 0 1px; margin-bottom: 10px;}
.alphabet a{color: #fff; background-color: #7e8aa2; display: inline-block; min-width: 30px; padding: 0 10px;font-size: 18px; font-family: 'Rajdhani', sans-serif; font-weight: 600;}
.alphabet a:hover, .alphabet a.active{color: #fff; background-color: #fb8512;}

/*books-list*/
#books-list{}
#books-list article{margin-bottom: 50px; }
#books-list h3{border-bottom: 1px solid #FF9700; padding-bottom: 5px; margin-bottom: 15px;}

/*book-page*/
#book-page{}
#book-page ul.topics-list li:after{content: ','}
#book-page ul.topics-list li:last-of-type:after{display: none;}
#book-page .social-icons{margin-top: 15px;}
#book-page .social-icons li{padding-right: 0;}
#book-page .title-book{margin: 0 0 10px; font-weight: 600; font-size: 24px;}
#book-page h3.overview{margin-top: 30px; font-weight: 600; font-size: 17px;}
#book-page em{color: #969696;}
#book-page a{color: #0070bd;}

/*quotes-of-book*/
.quotes-of-book{padding: 30px 0; background-color: transparent;}
.quotes-of-book ul.list-quote>li{padding: 15px 10% 15px 0; border-bottom: 1px solid #ccc;}
.quotes-of-book ul.list-quote>li .num{display: inline-block; margin-right: 20px; font-size: 32px; color: #ff9800;}
.quotes-of-book h4 a{font-weight: normal; font-family: 'Martel', serif;font-size: 28px; padding-top: 15px;color: #1b1b1b!important; }
.quotes-of-book .author a{display: block;text-align: right; font-size: 17px!important; color: #151515!important}

/*related-books*/
.related-books{background-color: #F6F9FF; padding: 20px 75px; margin: 30px 0;}
.related-books h3{font-weight: normal; margin: 0 0 20px;}

/*single-quote*/
#single-quote{}
#single-quote .carousel-control{top: 25%; display: block; color: #000; }
#single-quote .carousel-control:hover{ color: #fb8512;}
#single-quote blockquote{text-align: center; border: none; padding: 10px 12%; font-size: 28px; font-family: 'Martel', sans-serif;}
#single-quote blockquote img{margin: 0 auto 60px; text-align: center;}
#single-quote blockquote p{text-align: left;}
#single-quote footer{border-bottom: 1px solid #979797; margin-bottom: 10px;}
#single-quote .author{float: right; font-style: italic; font-size: 17px; color: #4a90e2; padding-right: 3px; font-weight: bold;}
.topic-list li{padding-right: 0;}
.topic-list li a{    padding: 2px 5px;
    border: 1px solid #ccc;
    border-radius: 5px;}


/*related-images*/
.module.related-images{ background-color: #fff; padding: 30px 0;}
.module.related-images h3{ margin-bottom: 20px;}
.module.related-images img{ width: 100%; max-height: inherit;}

.quotes-of-author .list_carousel li{width: 280px!important;}

/*authors-filter*/
#quote-page .authors-filter a{color: #4c7bb1; text-decoration: underline;}

/*success-articles*/
#success-articles .thumb{width: 100%;}
#success-articles article{ overflow: hidden; margin-bottom: 30px; min-height: 300px; position: relative;}
#success-articles h2 a{font-size: 20px;color: #373737;}
#success-articles .btn{position: absolute; bottom: 0; right: 0;}

/*single-article*/
#single-article{font-size: 16px;}
#single-article article{overflow: hidden; margin-bottom: 30px;}
#single-article article ul{padding: 0;}
#single-article article img{width: 30%; height: auto; margin-right: 3px;}
#single-article .module-title{margin: 30px 0 30px;  font-weight: 600;
    border-bottom: 1px solid #ff9800;
    font-size: 24px;
    padding-bottom: 5px;}
#single-article .thumb{margin-bottom: 20px; float: right; margin-right: 10px;}
#single-article .more-info .fa{margin-right: 5px;}

/*related-articles*/
.related-articles h2{margin-top: 0;}
.related-articles h2 a{font-size: 24px;color: #373737;}

/*static-page*/
#static-page{padding: 30px 0; }
#static-page h4{margin: 20px 0; font-family: 'Rajdhani', serif; font-weight: 600;}
#static-page p{margin-bottom: 30px;}
#static-page textarea{width: 100%; height: 150px; max-width: 100%;}
#static-page form p{font-size: 12px; margin-top: -10px;}
#static-page .red{color: #f00;}
#static-page input[type="submit"]{float: right;}

.menu-pages{margin-top: 30px; padding: 15px; margin-left: 50px;}
.menu-pages ul{padding-left: 20px;}
.menu-pages li{margin-bottom: 5px;}
.menu-pages li a{color: #4c7bb1; text-decoration: underline;}

/*search-result*/
#search-result article{margin-bottom: 30px;}
#search-result h2{margin-top: 0;}
#search-result h2 a{color: #ff9800; font-size: 24px; border-bottom: 1px solid #ff9800; }

/*search-form*/
#search-form{/*opacity: 0.8;*/ display: none; position: relative; width: 100%; top: -46px; border-bottom: 4px solid #FF9700; margin-bottom: -46px; z-index: 99999;}
#search-form input[type="text"]{position: absolute; width: 100%; left: 0;padding: 0 70px; height: 46px; font-size: 18px; border: none;}
#search-form #close-search, #search-form button{width: 50px; position: relative; z-index: 9999; height: 46px; font-size: 22px;}
#search-form button .fa{position: relative; top: 1px; right: 6px;}
#search-form #close-search .fa{position: relative; top: 4px; left: -4px; font-size: 24px;}
#search-form button{float: right;}

/*list-authors*/
#list-authors{margin-top: 60px;}
#list-authors th{vertical-align: middle; padding: 20px 0; text-align: center;}
#list-authors td{vertical-align: middle; text-align: center;}
#list-authors .author-col{text-align: left; background-color: #f6f9ff; padding-left: 20px; font-weight: bold;}
#list-authors .date{font-weight: bold;}
#list-authors .quotes-col{font-weight: bold; background-color: #fff2de;}
#list-authors .nationality-col{background-color: #fff2de;}
#list-authors img{max-height: 50px; margin-right: 20px;}
.occupation-list {margin: 0; line-height: 1;}
.occupation-list li{padding-left: 0; margin: 0;}
.occupation-list li:after{content: ', '}
.occupation-list li:last-child:after{display: none;}

/*one-categories*/
.one-categories{text-align: center; margin-bottom: 20px;}

/*inline-fact*/
.inline-fact{padding: 20px 0;  margin-bottom: 20px;}
.inline-fact blockquote{font-size: 28px; border: none; padding: 0;}
.inline-fact footer{border-bottom: 1px solid #ccc; margin-bottom: 10px;}
.inline-fact .date{font-size: 32px; color: #ff9800; font-family: 'Rajdhani'; font-weight: bold;}

/*happen-today*/
#happen-today form{display: none;}
#happen-today form button{padding: 1px 34px;}

/* Error Page */
.error-page{min-height: 500px; padding: 50px 0; text-align: center;}


@media screen and (max-width: 1200px){
    #all-quotes .one-quote blockquote a{font-size: 16px;}
    .menu-pages{margin-left: 0;}
    #blue-aria .module{min-height: 480px;}
}
@media screen and (max-width: 991px){
    #quote-page .letter-content li{width: 24%;}
    .quotes-of-book ul.list-quote>li{padding: 15px 0;}
    body{padding-top: 181px;}
    #subscribe{padding: 20px;}
    .fixed-social{display: none;}

    .social-media li{display: none;}
}
@media screen and (max-width: 767px){
	body{padding-top: 50px;}
	.social-media{display: none;}
    .navbar-inverse .navbar-toggle, .navbar-inverse .navbar-toggle:hover, .navbar-inverse .navbar-toggle:focus{float: right;}
    #header #top{padding: 0;}
    #header #logo{position: absolute; z-index: 9999; width: 120px; top: 8px; left: 30px;}
    #header #logo .black{display: none;}
    #header #logo .white{display: block;}
	/*#header{position: static;}*/
    #header #menu-bar{height: inherit; margin: 0 -15px;}
    #header .navbar-nav{margin: 0;}
    .navbar-inverse .navbar-toggle, .navbar-inverse .navbar-toggle:hover, .navbar-inverse .navbar-toggle:focus{background-color: transparent; box-shadow: none; border: none; margin-left: 10px;}
    #header .navbar-nav>li:first-of-type, #header .navbar-nav>li:last-of-type{float: none;}
    #header .navbar-nav>li{display: inline-block; width: 100%; }
    #header .navbar-nav>li a{border-bottom: 1px solid #7e8aa2;display: inline-block;padding: 15px 0; font-size: 24px; display: block; margin: 0;}
    #header .navbar-nav>li a:hover{background-color: #7e8aa2;}
    #header .navbar .active a:not(.btn){background-color: #fb8512;}
    #header .navbar .active a:after, #header .navbar-nav>li a:hover:after{display: none;}
    #header .navbar a.btn, #header .navbar a.btn:active{background-color: inherit; padding: 15px 0;}
    #header .navbar-nav>li:first-of-type a:hover, #header .navbar-nav>li:last-of-type a:hover, #header .navbar-nav>li.active:first-of-type a, #header .navbar-nav>li.active:last-of-type a{color: #fff;}
    

    #quote-page .letter-content li{width: 49%;}
    
    #all-quotes .one-quote{max-width: 100%; margin: 10px auto 20px;}
    .author-bibliographie .author-img{margin-bottom: 20px;}
    #book-page .title-book{margin-top: 20px;}
    .quotes-of-book h4 a{margin-top: 0; font-size: 18px;}
    .quotes-of-book ul.list-quote>li{padding-top: 0;}
    .related-books{padding: 20px;}
    .related-books h3{font-size: 20px;}
    #all-quotes .images-filter .filter-author{text-align: left;}
    
    #single-quote blockquote{font-size: 18px; padding: 10px 20%;} 
    .module.related-images a{margin-bottom: 15px; display: inline-block;}
    #single-article .module-title{margin-top: 0;}
    
    #blue-aria .module{min-height: inherit;}
    #blue-aria .more-things{position: static; float: none; margin-top: 10px;}
    #blue-aria img{max-width: 400px}

    #quote-day{text-align: center;}
    section#quote-day h2{font-size: 25px;}
    section#quote-day h4{font-size: 20px; line-height: 1.8;}

	#home-carousel{display: none;}
	.banner{margin-bottom: 30px; overflow: hidden;}
	#popular-things section li{width: 50%; float: left;}
	#popular-things section li:last-child{width: 100%; float: none; clear: both;}
	.banner .container{margin: 0; padding: 0;}
	#subscribe h2{font-size: 24px;}
	#subscribe form{padding: 0;}
	#blue-aria .more-things, #popular-things .col-sm-4:last-child .more-btn{display: block; padding: 7px; margin: 10px 0 30px; width: 95%;}
	#blue-aria h2{text-align: center;}
	#footer .social-icons{margin-top: 20px;}
	#footer{margin-bottom: 0; padding-bottom: 0;}
	#footer h4{margin: 0; text-align: center; background-color: #7e8aa2; padding: 10px; font-size: 22px; margin-bottom: 0px;}
	.footer-menu{padding: 0; overflow: hidden;}
	.footer-menu ul{margin-bottom: 0; padding-bottom: 0; overflow: hidden;}
	.footer-menu li{width: 50%; float: left; text-align: center; border: 1px solid #7e8aa2; }
	.footer-menu li a{font-size: 18px !important; display: block; padding: 10px;}
	.footer-menu li a:hover{background-color: #fb8512;}
	#footer #gotop{}

	#popular-things ul{margin-bottom: 0;}
	#popular-things section li a{text-align: center; border: 1px solid; border-radius: 5px; padding: 5px; display: block; width: 90%;} 

	.masonry-container{text-align: center;}
	.masonry-container > div {display: block; position: static!important; text-align: left; }

    .navbar-toggle .icon-bar{background-color: #fff;}
    .navbar-toggle{margin-top: 3px; margin-bottom: 0;}
    #header .right-menu{float: none; text-align: center; padding: 10px 0; }
    #header .navbar .navbar-collapse{margin: 10px 0;}

}
@media screen and (max-width: 480px){
    section#quote-day .wrapper{padding-left: 0;}
    section#quote-day .author a{margin-bottom: 20px}
    
    .social-media{float: none; width: 100%; text-align: center;}
    .carousel-indicators{bottom: -10px;}
    #all-quotes .one-quote blockquote a{font-size: 14px;}
    #all-quotes .one-quote .wrapper{padding: 15px 15px 0;}
}
@media screen and (max-width: 360px){
    #all-quotes .one-quote{max-width: 270px;}
}